import React from "react";
import logo from "../../assets/logo.png";
import "./bigHeader.css";

const BigHeader = () => {
  return (
    <div className="ostrovdi__big__header">
      <img src={logo} alt="logo" className="ostrovdi__big__header_logo" />
    </div>
  );
};

export default BigHeader;
