import React from "react";
import "./comingSoon.css";

const ComingSoon = () => {
  return (
    <div className="ostrovdi__comingSoon">
      <div className="ostrovdi__comingSoon-heading">
        <h1>We are under process of creating something amazing 🌟</h1>
        <h1>Our Website will be live pretty soon 🚀</h1>
      </div>
    </div>
  );
};

export default ComingSoon;
