import React from "react";

import { BigHeader, Copyright } from "./components";
import { ComingSoon } from "./containers";

import "./App.css";

const App = () => {
  return (
    <div className="App">
      <div className="ostrovdi__content">
        <BigHeader />
        <ComingSoon />
      </div>
      <div className="ostrovdi__footer">
        <Copyright />
      </div>
    </div>
  );
};

export default App;
