import React from "react";
import "./copyright.css";

const Copyright = () => {
  return (
    <div className="ostrovdi__copyright">
      <p>Copyright © 2022 Dmytro Ostrovka, LLC.</p>
      <p>All rights reserved.</p>
    </div>
  );
};

export default Copyright;